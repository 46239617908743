import React from 'react';
import { PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import { css, Theme } from '@emotion/react';

import Layout from '../layouts/Layout';
import FirstView from '@/components/organisms/common/FirstView';
import ISODesc from '@/components/organisms/quality/ISODesc';
import ISOImages from '@/components/organisms/quality/ISOImages';
import ContactLinkBlock from '@/components/organisms/common/ContactLinkBlock';
import BottomMenu from '@/components/organisms/common/BottomMenu';
import Seo from '@/components/organisms/common/Seo';

const FirstViewImage = (theme: Theme) => css({
  height: '25vh',
  [theme.breakpoints.down('xs')]: {
    height: '20vh',
  }
});
const QualityPage: React.VFC<PageProps> = () => {
  return (
    <Layout>
      <Seo
        title="品質管理"
        description="株式会社 富士見工務店は、ISO 9001、ISO 14001、ISO 45001を取得し、品質管理の向上に努めています。"
      />
      <FirstView
        titleEn="Quality"
        title="品質管理"
        titlePosition="right"
      >
        <StaticImage
          alt="QualityImage"
          src="../images/firstviews/quality.jpg"
          css={FirstViewImage}
          layout="fullWidth"
          objectPosition="50% 40%"
        />
      </FirstView>
      <ISODesc />
      <ISOImages />
      <ContactLinkBlock />
      <BottomMenu />
    </Layout>
  );
};

export default QualityPage;
