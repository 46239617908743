import React from 'react';

import { css, Theme } from '@emotion/react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

type QualityContentProps = {
  title: Array<string>;
  para: Array<string>;
  dark?: boolean;
};

const qualityContentWrapper = css({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
});

const qualityContentWrapperLight = (theme: Theme) => css({
  backgroundColor: theme.palette.background.default
});

const qualityContentWrapperDark = (theme: Theme) => css({
  backgroundColor: theme.palette.backgroundDark.default
});

const qualityContentBox = css({
  width: '90%',
  margin: '2rem auto'
});

const qualityContentTitle = css({
  margin: '.5rem auto',
  lineHeight: 2
});

const textDark = css({
  color: '#ffffff'
});

const qualityContentElement = css({
  display: 'inline-block'
});

const QualityContent: React.VFC<QualityContentProps> = (props) => {
  const { title, para, dark } = props;

  return (
    <Box
      css={[
        qualityContentWrapper,
        dark ? qualityContentWrapperDark : qualityContentWrapperLight,
      ]}
    >
      <Box css={qualityContentBox}>
        <Typography variant="h2" css={[qualityContentTitle, dark && textDark]}>
          {title.map(element => {
            return (
              <span key={ element } css={ qualityContentElement }>
                { element }
              </span>
            );
          })}
        </Typography>
        {para.map(element => {
          return (
            <Typography key={ element } css={dark && textDark}>
              { element }
            </Typography>
          );
        })}
      </Box>
    </Box>
  );
};

export default QualityContent;
