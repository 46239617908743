import React from 'react';

import { css, Theme } from '@emotion/react';
import Grid from '@mui/material/Grid';

import QualityTitle from '@/components/molecules/quality/QualityTitle';
import QualityContent from '@/components/molecules/quality/QualityContent';

const ISODesc: React.VFC = () => {
  return (
    <section>
      <Grid container>
        <Grid item xs={12} md={3}>
          <QualityTitle
            titleText={["What's", 'ISO ?']}
            backgroundColor="primary"
          />
        </Grid>
        <Grid item xs={12} md={9}>
          <QualityContent
            title={[
              'サービスの品質向上のための',
              '国際的マネジメントシステム規格'
            ]}
            para={[
              'ISOとは、国際標準化機構が提唱する、サービスの品質向上のためのマネジメントシステム規格です。',
              '私たちは、「品質」「環境」「労働安全」の3つの項目でISOを取得しています。'
            ]}
          />
        </Grid>
      </Grid>
      <Grid container direction="row-reverse">
        <Grid item xs={12} md={3}>
          <QualityTitle
            titleText={['Why', 'ISO ?']}
            backgroundColor="secondary"
          />
        </Grid>
        <Grid item xs={12} md={9}>
          <QualityContent
            title={[
              'すべては、よりよい活動のために',
            ]}
            para={[
              '私たちが活動する建設、土木の領域は、皆様に直接影響する業務がほとんどです。',
              'だからこそ、より高い品質、より良い環境づくりを進める必要があります。',
              'そしてそのためには、仲間が安心して働ける環境が不可欠です。',
              '動きの激しいこの時代。',
              'それでも絶対に変わらない意識を胸に、私たちは進みます。'
            ]}
            dark
          />
        </Grid>
      </Grid>
    </section>
  );
};

export default ISODesc;
