import React from 'react';

import { css, Theme } from '@emotion/react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

type WorksTypeDisplayProps = {
  backgroundColor?: 'primary' | 'secondary';
  titleText: Array<string>;
};

const qualityTitleBox = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '30vh',
  height: '100%'
});

const primaryBackground = (theme: Theme) => css({
  background: theme.palette.primaryGradation.default,
});

const secondaryBackground = (theme: Theme) => css({
  background: theme.palette.secondaryGradation.default,
});

const mainTitleStyles = css({
  color: '#ffffff',
  fontSize: '2rem',
  fontWeight: 600
});

const QualityTitle: React.VFC<WorksTypeDisplayProps> = (props) => {
  const { backgroundColor, titleText } = props;

  return (
    <Box css={[
      qualityTitleBox,
      backgroundColor === 'primary' && primaryBackground,
      backgroundColor === 'secondary' && secondaryBackground,
    ]}>
      <Box>
        <Typography align="center" variant="h2">
          {titleText.map(element => {
            return (
              <Typography css={mainTitleStyles} key={ element }>
                { element }
              </Typography>
            );
          })}
        </Typography>
      </Box>
    </Box>
  );
};

export default QualityTitle;
