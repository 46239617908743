import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { css, Theme } from '@emotion/react';
import Grid from '@mui/material/Grid';

const imageStyles = css({
  display: 'block',
  width: '90%',
  maxWidth: '360px',
  margin: '2rem auto',
});

const imagesBox = (theme: Theme) => css({
  backgroundColor: theme.palette.background.default,
});

const ISOImages: React.VFC = () => {
  return (
    <section>
      <Grid container css={imagesBox}>
        <Grid item xs={12} md={4}>
          <StaticImage
            alt="ISO9001"
            src="../../../images/ISO/ISO9001.jpg"
            css={imageStyles}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <StaticImage
            alt="ISO14001"
            src="../../../images/ISO/ISO14001.jpg"
            css={imageStyles}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <StaticImage
            alt="ISO45001"
            src="../../../images/ISO/ISO45001.jpg"
            css={imageStyles}
          />
        </Grid>
      </Grid>
    </section>
  );
};

export default ISOImages;
